import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import './App.css';
import YourFile from './alunos.xlsx';

function App() {
  const [email, setEmail] = useState('');
  const [discordInvite, setDiscordInvite] = useState('');
  const [csvData, setCsvData] = useState('');
  const [emailFound, setEmailFound] = useState(null);
  const [text, setText] = useState('');


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };




  const apiUrl = 'https://base-discord-19e583f9e543.herokuapp.com/generate-invite';

  const checkEmailInCSV = async () => {
    try {
      const fileData = await fetch(YourFile).then((response) => response.blob());

      const reader = new FileReader();
      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0]; // Suponha que você deseja ler a primeira planilha
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        // Verifique o email no arquivo (substitua com sua lógica de verificação)
        const targetEmail = email;
        const foundEmail = jsonData.some((row) => row.email === targetEmail);

        // Chame setEmailFound aqui para definir o resultado da verificação
        setEmailFound(foundEmail);

        // Verifique emailFound e chame generateInvite conforme necessário
        if (foundEmail) {
          setText("Generating Invite...")
          generateInvite();
        } else {
          setText('Email not found. Please insert the email that you used to register for The Meta-Analysis Academy.');
          console.log('O email não existe.');
        }
      };

      reader.readAsArrayBuffer(fileData);
    } catch (error) {
      console.error('Erro ao carregar ou verificar o arquivo XLSX', error);
      setEmailFound(false);
    }
  };



  async function generateInvite() {
    try {
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error('Erro ao buscar o inviteLink');
      }

      const data = await response.json();
      // Supondo que o inviteLink esteja em um campo 'inviteLink' no JSON retornado
      setDiscordInvite(data.inviteLink);
    } catch (error) {
      console.error('Erro ao buscar o inviteLink:', error);
    }
  }

  return (
    <div className="App">
      <div className='container'>
        <img className="logo" src='https://hotmart.s3.amazonaws.com/product_pictures/d231d476-62f4-4ffd-a156-ed5a99812bf6/logometaacademy.png'></img>
        <h1 className='title'>GENERATE YOUR DISCORD INVITE</h1>

        <br></br>
        <input type="email" value={email} onChange={handleEmailChange} className='inputEmail' placeholder='example@example.com' />
        <br></br>
        <button onClick={checkEmailInCSV} className='buttonSend'>GENERATE</button>
        <br></br>
        <h1 className='title2'>{text}</h1>

        {discordInvite && (
          <div className='linkContainer'>
            <a href={discordInvite} target="_blank" rel="noopener noreferrer" className='inviteLink'>
              DISCORD INVITE
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
